"use client";
import Loader from "@/components/loader/loader";
import { useLocalsListStore } from "@/zustand/localesList";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import api from "../../../api";
import { getCookie } from "../../../helper/helper";
import Aboutus from "../about-us/page";
import BlackbullNewCollection from "../blackbull-new-collection/page";
import HomePage from "../home/page";
import NewOctaveCollection from "../new-octave-collection/page";
import ProductDetails from "../product-details/page";
import Rarestcollection from "../rarest-collection/page";
import ShopNow from "../shop-now/page";
import SingleCask from "../single-cask/page";
import OurAwards from "../our-awards/page";
import IndainSummerGinpage from "../indian-summer-gin/page";
import PrivacyPolicy from "../privacy-policy/page";
import Number868Collection from "../number-868/page";

const Master = () => {
  const path = usePathname();
  const [meta, setMeta]: any = useState({});
  const [pageData, setData]: any = useState({});
  const [template, setTemplateName]: any = useState("");
  const [countryCode, setCountryCode]: any = useState("");
  const [loader, setLoader] = useState(false);
  const localesList = useLocalsListStore((state: any) => state.localesList);
  const locale = localesList.locale || getCookie("locale");

  const fetchPageData = async (props: any) => {
    let locale = getCookie("locale");
    setLoader(true);
    const response: any = await api
      .getPageData(path, locale ? { locale: locale } : {})
      .catch((err: any) => {
        console.log("ERROR: ", err);
        setLoader(false);
      });
    setData(response?.data);

    setMeta(response?.data?.data?.attributes?.seo);
    setCountryCode(path.split("/")[1]);
    let template: any = response ? response?.headers["template-name"] : "";
    setTemplateName(template);
    setLoader(false);
    if (!template) {
      getElement();
    }
  };

  useEffect(() => {
    const currentpath = path?.split("/")[1];

    if (currentpath || locale) {
      fetchPageData(currentpath || locale);
    }
  }, [locale, path]);

  const getElement = () => {
    if (loader == false && template) {
      switch (template) {
        case "/":
          return (
            <HomePage data={pageData} meta={meta} countryCode={countryCode} />
          );

        case "template-about-us":
          return (
            <Aboutus data={pageData} meta={meta} countryCode={countryCode} />
          );

        case "rarest-of-the-rare-collection":
          return (
            <Rarestcollection
              data={pageData}
              meta={meta}
              countryCode={countryCode}
            />
          );

        case "blackbull-collection-page":
          return (
            <BlackbullNewCollection
              data={pageData}
              meta={meta}
              countryCode={countryCode}
            />
          );

        case "port-ellen-product-detail":
          return (
            <ProductDetails
              data={pageData}
              meta={meta}
              countryCode={countryCode}
            />
          );

        case "blackbull-product-detail":
          return (
            <ProductDetails
              data={pageData}
              meta={meta}
              countryCode={countryCode}
            />
          );

        case "ocatave-product-detail":
          return (
            <ProductDetails
              data={pageData}
              meta={meta}
              countryCode={countryCode}
            />
          );

        case "nickfaldo-totl-product-detail":
          return (
            <ProductDetails
              data={pageData}
              meta={meta}
              countryCode={countryCode}
            />
          );

        case "indian-summer-gin-product-detail":
          return (
            <ProductDetails
              data={pageData}
              meta={meta}
              countryCode={countryCode}
            />
          );

        case "special-releases-detail":
          return (
            <ProductDetails
              data={pageData}
              meta={meta}
              countryCode={countryCode}
            />
          );

        case "single-cask-product-detail":
          return (
            <ProductDetails
              data={pageData}
              meta={meta}
              countryCode={countryCode}
            />
          );

        case "rarest-of-the-rare-product-detail":
          return (
            <ProductDetails
              data={pageData}
              meta={meta}
              countryCode={countryCode}
            />
          );

        case "octave-collection":
          return (
            <NewOctaveCollection
              data={pageData}
              meta={meta}
              countryCode={countryCode}
            />
          );

        case "single-cask":
          return (
            <SingleCask data={pageData} meta={meta} countryCode={countryCode} />
          );

        case "number-868":
          return (
            <Number868Collection
              data={pageData}
              meta={meta}
              countryCode={countryCode}
            />
          );

        case "our-award":
          return (
            <OurAwards data={pageData} meta={meta} countryCode={countryCode} />
          );

        case "shop-now":
          return (
            <ShopNow data={pageData} meta={meta} countryCode={countryCode} />
          );

        case "privacy-policy":
          return (
            <PrivacyPolicy
              data={pageData}
              meta={meta}
              countryCode={countryCode}
            />
          );

        case "indian-summer-gin":
          return (
            <IndainSummerGinpage
              data={pageData}
              meta={meta}
              countryCode={countryCode}
            />
          );

        default:
          return null;
      }
    }
    // else if (!template && loader == false) {
    //   return <Custom404 />;
    // }
    else {
      return <Loader />;
    }
  };

  return getElement();
};

export default Master;
